<template>
  <div>
    <div id="map" style="height: 400px; width: 100%">
      <!-- <l-map ref="myMap" :zoom="zoom" :center="center" @ready="onReady" @click="onClick">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      </l-map>-->
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import {} from "leaflet-search";
import "leaflet/dist/leaflet.css";
import "leaflet-search/dist/leaflet-search.min.css";
// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
export default {
  name: "Maps",
  components: {
    // LMap,
    // LTileLayer,
    // LMarker
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return { latitude: null, longitude: null, address: "" };
      }
    }
  },
  data() {
    return this.initData();
  },
  mounted() {
    this.initMap();
    // this.initMarker();
  },
  methods: {
    initData() {
      return {
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        titleLayerProps: {
          maxZoom: 18,
          attribution:
            '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          tileSize: 512,
          zoomOffset: -1
        },
        zoom: 9,
        center: [-6.175372, 106.827194],
        map: null,
        titleLayer: null,
        layers: [
          {
            id: 0,
            name: "Your Location",
            active: false,
            features: [
              {
                id: 0,
                name: "Your location tesadf asdfasdf asdfadsf",
                type: "marker",
                icon: "firstIcon",
                coords: [-6.17537, 106.827199]
              }
            ]
          }
        ],
        firstIcon: L.icon({
          iconUrl:
            "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|e85141&chf=a,s,ee00FFFF",
          iconSize: [25, 38],
          iconAnchor: [12, 39],
          popupAnchor: [-3, 0],
          shadowUrl:
            "https://unpkg.com/browse/leaflet@1.6.0/dist/images/marker-shadow.png",
          shadowSize: [68, 95],
          shadowAnchor: [22, 94]
        }),
        secondIcon: L.icon({
          iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|e85141&chf=a,s,ee00FFFF",
          iconSize: [25, 38],
          iconAnchor: [12, 39],
          popupAnchor: [-3, 0],
          shadowUrl:
            "https://unpkg.com/browse/leaflet@1.6.0/dist/images/marker-shadow.png",
          shadowSize: [68, 95],
          shadowAnchor: [22, 94]
        }),
        thirdIcon: L.icon({
          iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|3d67c2&chf=a,s,ee00FFFF",
          iconSize: [25, 38],
          iconAnchor: [12, 39],
          popupAnchor: [-3, 0],
          shadowUrl:
            "https://unpkg.com/browse/leaflet@1.6.0/dist/images/marker-shadow.png",
          shadowSize: [68, 95],
          shadowAnchor: [22, 94]
        }),
        lat: null,
        lng: null,
        address: "",
        nominatimData: null
      };
    },
    initMap() {
      this.map = L.map("map").setView(this.center, this.zoom);
      this.titleLayer = new L.tileLayer(this.url, this.titleLayerProps).addTo(
        this.map
      );
      this.map.on("click", this.onMapClick);
      // this.map.on("locationfound", this.onLocationFound);
      this.map.locate({ setView: true, maxZoom: 10 });
      this.map.addControl(
        new L.Control.Search({
          url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
          jsonpParam: "json_callback",
          propertyName: "display_name",
          propertyLoc: ["lat", "lon"],
          marker: L.circleMarker([0, 0], { radius: 30 }),
          autoCollapse: true,
          autoType: false,
          minLength: 2
        })
      );
    },
    initMarker() {
      this.layers.forEach(layer => {
        if (layer.active) {
          const markerFeatures = layer.features.filter(
            feature => feature.type === "marker"
          );

          markerFeatures.forEach(feature => {
            var icon = null;
            if (feature.icon == "firstIcon") {
              icon = this.firstIcon;
            } else if (feature.icon == "secondIcon") {
              icon = this.secondIcon;
            }

            feature.leafletObject = L.marker(feature.coords, {
              icon: icon
            })
              .addTo(this.map)
              .bindPopup(
                L.popup({
                  className: "custom-popup"
                }).setContent(feature.name)
              )
              .openPopup();
          });
        }
      });
    },
    onMapClick(event) {
      this.lat = event.latlng.lat;
      this.lng = event.latlng.lng;
      this.$http
        .get("https://nominatim.openstreetmap.org/reverse", {
          params: {
            format: "jsonv2",
            lat: event.latlng.lat.toString(),
            lon: event.latlng.lng.toString()
          }
        })
        .then(data => {
          this.nominatimData = data;
          this.address = data.display_name;
          var popup = L.popup();
          popup
            .setLatLng(event.latlng)
            .setContent(data.display_name)
            .openOn(this.map);
        });
    },
    onLocationFound(event) {
      var radius = event.accuracy;
      var popup = L.popup({
        className: "custom-popup"
      }).setContent(
        "You location are within " + radius + " meters from this point"
      );
      L.marker(event.latlng, {
        icon: this.thirdIcon
      })
        .addTo(this.map)
        .bindPopup(popup)
        .openPopup();
      L.circle(event.latlng, radius).addTo(this.map);
    },
    setDefaultMarker() {
      if (this.lat && this.lng) {
        L.marker(
          [this.lat, this.lng],
          {
            icon: this.firstIcon
          },
          10
        )
          .addTo(this.map)
          .bindPopup(
            L.popup({
              className: "custom-popup"
            }).setContent(this.address)
          )
          .openPopup();
      }
      this.map.locate({ setView: true, maxZoom: 10 });
    }
  },
  watch: {
    nominatimData(data) {
      if (data) {
        this.$emit("data", {
          latitude: data.lat,
          longitude: data.lon,
          address: data.display_name
        });
      } else {
        this.$emit("data", {
          latitude: null,
          longitude: null,
          address: ""
        });
      }
    },
    data(val) {
      this.lat = val.latitude;
      this.lng = val.longitude;
      this.address = val.address;
      this.setDefaultMarker();
    }
  }
};
</script>
<style>
.custom-popup {
  margin-left: 4px;
  padding-bottom: 26px;
}
</style>